import styled from "styled-components";

// Services
import intlService from "../../services/intl";

// Components
import PageSEO from "../seo/Page";
import PageHero from "../../components/PageHero";
import JournalNav from "./JournalNav";
import JournalSection from "./JournalSection";
import ProductCategoryCards from "../product/ProductCategoryCards";

// Utils
import renderComponents from "../../utils/articles";

// Styled Elements
const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

const HeroSection = styled.section`
  position: relative;
  background-color: ${(p) => p.background};
`;

const JournalLanding = (props) => {
  const { data, seo } = props;
  const {
    desktopImage,
    mobileImage,
    heroBackgroundColor,
    featuredArticles,
    categorySections,
  } = data;

  const navCategories = categorySections.map((category) => category.category);

  return (
    <>
      <PageSEO {...seo} />
      <Wrapper>
        <>
          <HeroSection>
            <PageHero
              backgroundColor={heroBackgroundColor}
              imageMobile={mobileImage}
              imageDesktop={desktopImage}
            />
          </HeroSection>
          <JournalNav currentCategory={null} />
        </>

        <JournalSection
          summary={intlService.t(
            "journal.landing.featured-description",
            "Make Your Self (v): To commit to yourself every day, with good habits and relentless self-determination.",
          )}
          className={"mt-6 mt-md-7 mb-7 mb-md-9"}
        >
          {renderComponents(featuredArticles, { slug: "featured" })}
        </JournalSection>

        {categorySections.map((categorySection) => {
          const { featuredArticles, category } = categorySection;
          if (!featuredArticles) return null;
          return (
            <JournalSection
              key={category.key}
              title={category.title}
              className={"mt-7 mt-9 mb-7 mb-md-9"}
              category={category}
            >
              {renderComponents(featuredArticles, category)}
            </JournalSection>
          );
        })}

        <ProductCategoryCards className={"mt-7 mt-md-9 mb-7 mb-md-9"} />
      </Wrapper>
    </>
  );
};

export default JournalLanding;
