import { Component } from "react";
import { graphql } from "gatsby";

// Components
import JournalLanding from "../../components/journal/JournalLanding";

export default class Lab extends Component {
  componentDidMount() {
    this.props.updatePageData({
      label: "Blog",
    });
  }

  render() {
    let { contentfulPageFeatured } = this.props.data;
    let { slug, openGraphImage, pageTitle, pageDescription } =
      contentfulPageFeatured;

    let seo = {
      pagePath: slug,
      title: pageTitle,
      description: pageDescription,
      image: {
        src: openGraphImage.file.url,
        width: openGraphImage.file.details.image.width,
        height: openGraphImage.file.details.image.height,
      },
    };

    return <JournalLanding data={contentfulPageFeatured} seo={seo} />;
  }
}

export const pageQuery = graphql`
  query ContentPageQuery($locale: String!) {
    contentfulPageFeatured(node_locale: { eq: $locale }) {
      slug
      pageTitle
      pageDescription
      openGraphImage {
        file {
          url
          details {
            image {
              width
              height
            }
          }
        }
      }
      heroBackgroundColor
      desktopImage {
        file {
          url
        }
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          width: 1920
          quality: 90
        )
      }
      mobileImage {
        file {
          url
        }
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          width: 912
          quality: 90
        )
      }
      featuredArticles {
        __typename
        type
        alignment
        quoteTitle
        quote {
          childMarkdownRemark {
            html
          }
        }
        fullWidthImage {
          file {
            url
          }
          desktop: gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 1920
            quality: 100
          )
        }
        articles {
          ...featuredArticlesFragment
          heroImage {
            file {
              url
            }
            desktop: gatsbyImageData(
              layout: CONSTRAINED
              width: 960
              height: 560
              quality: 100
              cropFocus: LEFT
              resizingBehavior: FILL
            )
            mobile: gatsbyImageData(
              layout: CONSTRAINED
              width: 960
              height: 560
              quality: 100
              cropFocus: LEFT
              resizingBehavior: FILL
            )
          }
          ...expandPrimaryContent
        }
      }
      categorySections {
        category {
          title
          slug
        }
        summary {
          childMarkdownRemark {
            html
          }
        }
        featuredArticles {
          __typename
          type
          alignment
          quoteTitle
          quote {
            childMarkdownRemark {
              html
            }
          }
          fullWidthImage {
            file {
              url
            }
            desktop: gatsbyImageData(
              layout: CONSTRAINED
              placeholder: DOMINANT_COLOR
              width: 1920
              quality: 100
            )
          }
          articles {
            ...featuredArticlesFragment
            heroImage {
              file {
                url
              }
              desktop: gatsbyImageData(
                layout: CONSTRAINED
                width: 960
                height: 560
                quality: 100
                cropFocus: LEFT
                resizingBehavior: FILL
              )
              mobile: gatsbyImageData(
                layout: CONSTRAINED
                width: 960
                height: 560
                quality: 100
                cropFocus: LEFT
                resizingBehavior: FILL
              )
            }
            ...expandPrimaryContent
          }
        }
      }
    }
  }
`;
